import React from "react";
import logo from "../assets/img/logo.png";
import Navlinks from "../Components/Navlinks";
import NumberFormat from "react-number-format";
import lineImg from "../assets/img/line-img.png";
import brandLogo from "../assets/img/brand-logo.png";
import formLine from "../assets/img/form--line.png";
import TermsOfUse from "../Components/TermsOfUse";
import PrivacyPolicy from "../Components/PrivacyPolicy";
import PartnerList from "../Components/PartnerList";
import ScrollToTop from "../Components/ScrollToTop";
import Description from "../Components/Description";
import ReactGA from 'react-ga4';

export default class Step4 extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ppIsOpen: false };
    }

    setPpIsOpen = (val) => {
        this.setState({ ppIsOpen: val });
    }

    submitStep = () =>{
        let isError = false;

        if (!this.props.values.firstName.length) {
            this.props.setError('firstName');
            isError = true;
        }

        if (!this.props.values.lastName.length) {
            this.props.setError('lastName');
            isError = true;
        }

        if (!this.props.values.phone.length || this.props.values.phone.length !== 10) {
            this.props.setError('phone');
            isError = true;
        }

        if (this.props.values.phone.length === 10) {
            if (this.props.values.phone[0] === "0" || this.props.values.phone[0] === "1") {
                this.props.setError('phone');
                isError = true;
            }
        }

        if (!isError) {
            const params = new URLSearchParams(window.location.search);
            params.set('SourceID', this.props.values.publisher_id);
            params.set('ExtClickID', window.leadId);
            params.set('zipcode', this.props.values.zip);
            params.set('State', this.props.values.state);
            params.set('creditscore', this.props.values.creditScore);
            params.set('loanbalance', this.props.values.remainingMortgageBalance);
            params.set('propertyvalue', this.props.values.estimatedHomeValue);
            params.set('PropertyUse', "");
            params.set('MediaChannel', "");
            params.set('EmploymentStatus', "");

            // MAO PII added 

            params.set('FName', encodeURIComponent(this.props.values.firstName));
            params.set('LName', encodeURIComponent(this.props.values.lastName));
            params.set('Email', this.props.values.email);
            params.set('Phone', encodeURIComponent(this.props.values.phone));
            params.set('City', encodeURIComponent(this.props.values.city));
            let maoAddress = encodeURIComponent(this.props.values.address) + "," + encodeURIComponent(" " + this.props.values.city) + "," + encodeURIComponent(" " + this.props.values.state + " " + this.props.values.zip);
            params.set('Address', maoAddress);


            if (this.props.values.cashOut === 'No') {
                params.set('CashOut', 'No');
            }else {
                params.set('CashOut', 'Yes');
            }

            if (this.props.values.activeMilitary === 'Y') {
                params.set('MilitaryStatus', 'Yes');
            }else {
                params.set('MilitaryStatus', 'No');
            }

            if (this.props.values.employmentStatus === 'Employed') {
                params.set('EmploymentStatus', 'Employed');
            }else if (this.props.values.employmentStatus === 'Self-Employed'){
                params.set('EmploymentStatus', 'Self-Employed');
            }else if (this.props.values.employmentStatus === 'Retired'){
                params.set('EmploymentStatus', 'Retired');
            }else if (this.props.values.employmentStatus === 'Other'){
                params.set('EmploymentStatus', 'Not Employed');
            }


            if (this.props.values.homeType === 'Single Family') {
                params.set('PropertyType', 'Single Family');
            }else if (this.props.values.homeType === 'Condominium'){
                params.set('PropertyType', 'Condo/Townhome');
            }else if (this.props.values.homeType === 'Multi-Family Dwelling Unit'){
                params.set('PropertyType', 'Multi-Unit');
            }else if (this.props.values.homeType === 'Mobile Home'){
                params.set('PropertyType', 'Mobile');
            }


            params.toString();
            
            window.history.replaceState({}, '', `${window.location.pathname}?${params.toString().replaceAll('+', ' ').replaceAll('%2C', ',')}`);
            ReactGA.event({
                category: 'Step 4, Name, Phone',
                action: 'Step 4, Name, Phone',
                label: 'Step 4, Name, Phone'
            });

            this.props.next();
        }

        ScrollToTop();
    }

    componentDidMount() {
        ReactGA.initialize(process.env.REACT_APP_GA);
        ReactGA.send({ hitType: 'pageview', page: '/step4'});
    }

    handleChange = (e) => {
        if (e.currentTarget.value.match(/^[A-Za-z]+$/) || e.currentTarget.value == "") {
            this.props.manualHandleChange(e.currentTarget.dataset.type, e.currentTarget.value);
        }
    }

    render () {
        return (
            <>
            <section className="header index-banner">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <a className="navbar-brand brand" href="/">
                            <img src={logo} alt="logo-image" className="img-fluid logo"/>
                        </a>
                    </nav>
                    <span className="logo-text">Catch your lower rate today!</span>
                    <div className="row banner-content">
                        <div className="col-lg-7 col-md-7 orderMobileLast">
                            <img src={lineImg} className="img-fluid line-img" alt=""/>
                            <ul className="our-list">
                                <li> Lower your payment</li>
                                <li>Pay off your loan faster</li>
                                <li>Get a low rate for the life of your loan</li>
                            </ul>
                            <div className="brand-logo">
                                <img src={brandLogo} className="img-fluid brand-img transparent-background" alt=""/>
                            </div>
                            <div className=" banner-links mm d-flex ">
                                <Navlinks leftSpace={true}/>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5">
                            <div className="banner-form">
                                <div className="row mb-2">
                                    <div className="col">
                                        <h1 className="form-heaading mm  text-left">Step 4</h1>
                                    </div>
                                    <div className="col text-right">
                                        <a className={"form-back-button"} onClick={this.props.back}><i className="fas fa-arrow-left"/> Back</a>
                                    </div>
                                </div>
                                <img src={formLine} className="img-fluid form-line-img" alt=""/>
                                <form>
                                    <input type="hidden" name="universal_leadid" value="" id="leadid_token"/>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className={`form-control border-input ${!this.props.isError('firstName') ? '' : 'invalid'}`}
                                            placeholder="First Name"
                                            value={this.props.values.firstName}
                                            data-type='firstName'
                                            onChange={this.handleChange}
                                        />
                                        {this.props.isError('firstName') &&
                                        <div className="invalid-feedback d-block">
                                            Please enter your first name.
                                        </div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className={`form-control border-input ${!this.props.isError('lastName') ? '' : 'invalid'}`}
                                            placeholder="Last Name"
                                            value={this.props.values.lastName}
                                            data-type='lastName'
                                            onChange={this.handleChange}
                                        />
                                        {this.props.isError('lastName') &&
                                        <div className="invalid-feedback d-block">
                                            Please enter your last name.
                                        </div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <NumberFormat
                                            className={`form-control border-input ${!this.props.isError('phone') ? '' : 'invalid'}`}
                                            placeholder="Phone Number"
                                            format="+1 (###) ###-####"
                                            mask="_"
                                            value={this.props.values.phone}
                                            onValueChange={(values) => {
                                                const {formattedValue, value} = values;
                                                this.props.manualHandleChange('phone',value);
                                                this.setState({ phoneCorrect: true });
                                            }}
                                        />
                                        {this.props.isError('phone') &&
                                        <div className="invalid-feedback d-block">
                                            Please enter your phone number.
                                        </div>
                                        }
                                    </div>
                                    <label for="leadid_tcpa_disclosure" > 
                                    <div className="best-content text-left mm">
                                    <input type="hidden" id="leadid_tcpa_disclosure"/>
                                        By clicking the button below, you acknowledge, consent, and agree to our terms at the bottom of this page.
                                    </div>
                                    <div className="footer-form-button  multi-form submit-form">
                                        {/* <a id="nextBtn" onClick={this.submitStep}
                                           className="banner-btn mb text-uppr">Submit
                                            <i className="fas fa-arrow-right btn-arrow"/>
                                        </a> */}
                                        <input type="button" onClick={this.submitStep} className="submit-btn mb text-uppr" value="submit " /> 
                                        <i className="fas fa-arrow-right btn-arrow submit-arrow"/>
                                    </div>
                                    <div className="best-content mm text-left">
                                        By clicking "Submit" you electronically agree to share your information with up to 6 <PartnerList noClass={true}/> (possibly including Rocket
                                                Mortgage) and provide your prior "written" consent for them to contact you
                                                (including through automated means; autodialing, text and pre-recorded
                                                messaging) via telephone, mobile device (including SMS and MMS) and/or
                                                email, even if your telephone number is currently listed on any state,
                                                federal or corporate Do Not Call list and you agree to our <TermsOfUse noClass={true} setPpIsOpen={this.setPpIsOpen} /> and <PrivacyPolicy noClass={true} ppIsOpen={this.state.ppIsOpen} setPpIsOpen={this.setPpIsOpen} />. You agree
                                                that we can share your personal data with third parties, such as our
                                                mortgage partners, service providers and other affiliates, and that we
                                                can use this data for marketing and analytics, and to make your
                                                experience easier. This consent is not a condition of a
                                                purchase.
                                    </div>
                                    </label>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Description submitStep = {this.submitStep}/>
            </>
        )
    }
}
