import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import parse from "html-react-parser";

export default function TermsOfUse({ noClass, leftSpace, setPpIsOpen }) {
    const [isOpen, setIsOpen] = useState(false);
    const [content, setContent] = useState(false);

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    function handleClick($event) {
        if ($event.target.id === 'ppTag') {
            setIsOpen(false);
            setPpIsOpen(true);
        }
    }

    const customStyles = {
        content: {
          width: '90%',
          height: '70%',
        },
      };

    useEffect(() => {
        fetch('https://api.reallygreatrate.com/landingpagecontent/terms-of-use-refinance', {
            method: 'POST',
            body: JSON.stringify({
                sitename:"RateCatcher.com",
                pplink: ''
            }),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          })
         .then((response) => response.json())
         .then((resp) => {
            setContent(resp.data.html);
         })
         .catch((err) => {
            console.log(err.message);
        });
    }, []);

    return (
        <>
            <a className={"pointer-cursor "+(noClass?'':'nav-link') + (leftSpace ? ' space-left' : '')} onClick={toggleModal}>Terms of use</a>
            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="Terms of use"
                className="contentModal"
                overlayClassName="contentModalOverlay"
                ariaHideApp={false}
                style={customStyles}
            >
                <div className="contentModalHeader">
                    <div className="row">
                        <div className="col-auto">
                            <h3>TERMS OF USE</h3>
                        </div>
                        <div className="col text-right">
                            <button type="button" className="close" onClick={toggleModal} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="contentModalText" onClick={ ($event) => { handleClick($event) } }>
                    {content &&
                        parse(content)
                    }
                </div>
            </Modal>
        </>
    );
}
