import React from 'react';
import loanStepsImg from '../assets/img/loan-steps-img.png';
import loanStepsImg2 from '../assets/img/loan-steps-img2.png';
import loanStepsImg3 from '../assets/img/loan-steps-img3.png'

const Description = (props) => {
    return (
        <section className="body-bg-img">
            <section className="loan-steps-section d-lg-block d-md-block d-sm-block d-none">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-12">
                            <h6 className="loan-content mm m-0">Our Services</h6>
                            <h1 className="loan-heading mbb">
                                Why You Should Refinance</h1>
                        </div>
                    </div>
                    <div className="row loan-all-step">
                        <div className="col-lg-4 col-md-4">
                            <div className="loan-steps">
                                <img src={loanStepsImg} alt="Lower Your Payment" className="img-fluid"/>
                                <h3 className="loan-step-heading mm">Lower Your Payment</h3>
                                <div className="loan-step-content ml">Today's rates may be much lower than your current rate. That’s why we put together customized quotes for each of our clients.</div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="loan-steps">
                                <img src={loanStepsImg2} alt="Pay Off Your Loan Faster" className="img-fluid"/>
                                <h3 className="loan-step-heading mm">Pay Off Your Loan Faster</h3>
                                <div className="loan-step-content ml">With lower rates you may be able to switch to a 20 or 15 year term without a major payment increase. That means you can pay off your home much faster.
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="loan-steps">
                                <img src={loanStepsImg3} alt="Get a Low Rate for the Life of Your Loan" className="img-fluid"/>
                                <h3 className="loan-step-heading mm">Get a Low Rate for the Life of Your Loan</h3>
                                <div className="loan-step-content ml">Lock in a 30 year fixed rate all time lows. Rest assured your rate will not increase for the life of your mortgage loan.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="get-started-button">
                        <a onClick={props.submitStep} className="banner-btn mm text-uppr">Get STARTED
                            <i className="fas fa-angle-right btn-arrow"/>
                        </a>
                    </div>
                    <div className="row ">
                        <div className="col-lg-12">
                            <div className="footer-about-content ml">
                                <p> Rate-Catcher.com an RGR Marketing website is not a lender, broker, or other provider of financial or insurance products or
                                    services. The information you provide to us is not an application for a loan or other financial product
                                    or service. We do not use this information to pre-qualify you for a loan or financial products or
                                    services. We provide the information you give to us to third party providers of these products and
                                    services. If the information you provide to us matches the criteria they are seeking, they will contact
                                    you
                                    directly. In many cases we may deploy SMS to you to facilitate an inbound call to the service or product
                                    provider. We are paid by such providers for each consumer they contact directly and/or provide services
                                    or products. You are not charged for our services. We do not guarantee that a provider or Lender will
                                    accept your registration information or that their products or services will meet your needs. Only the
                                    Lender can provide you with rates, terms and products or services you have inquired about. It may not be
                                    the best product or service available on the market. Not all product or service providers will offer you
                                    the products that we advertise. Completing our forms does not obligate you to purchase a service or
                                    product nor does it obligate a product or service provider to provide you with any particular service
                                    about which you may have inquired. Available rates and terms in our marketing materials are subject to
                                    change without notice. You must be a legal resident of the United States to use this Website and we specifically prohibit access or use by residents of other countries including but not limited to Canadian and European Union Member Citizens. <a href="https://www.nmlsconsumeraccess.org/" target="_blank">Nationwide Mortgage Licensing System (NMLS) ID # 2721</a>.

                                </p> <a href="http://www.refiexpert.net/" target="_blank">Licenses and Disclosures</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="step-mobile-section d-lg-none d-md-none d-sm-none d-block text-center mt-0">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-12">
                            <h6 className="loan-content mm m-0">Our Services</h6>
                            <h1 className="loan-heading mbb">
                                Why You Should Refinance</h1>
                        </div>
                    </div>
                    <div className="row text-center  loan-all-step">
                        <div className="col-lg-10">
                            <div className="owl-carousel owl-items owl-theme ">
                                <div className="item">
                                    <div className="loan-steps">
                                        <img src={loanStepsImg} alt="" className="img-fluid"/>
                                        <h3 className="loan-step-heading mm">Lower Your Payment</h3>
                                        <div className="loan-step-content ml">Today's rates may be much lower than your current rate. That’s why we put together customized quotes for each of our clients.</div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="loan-steps">
                                        <img src={loanStepsImg2} alt="" className="img-fluid"/>
                                        <h3 className="loan-step-heading mm">Pay Off Your Loan Faster</h3>
                                        <div className="loan-step-content ml">With lower rates you may be able to switch to a 20 or 15 year term without a major payment increase. That means you can pay off your home much faster.</div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="loan-steps">
                                        <img src={loanStepsImg3} alt="" className="img-fluid"/>
                                        <h3 className="loan-step-heading mm">Get a Low Rate for the Life of Your Loan</h3>
                                        <div className="loan-step-content ml">Lock in a 30 year fixed rate all time lows. Rest assured your rate will not increase for the life of your mortgage loan.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-lg-12">
                            <div className="get-started-button">
                                <a onClick={props.submitStep} className="banner-btn mm text-uppr">Get STARTED
                                    <i className="fas fa-angle-right btn-arrow"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-lg-12">
                            <div className="footer-about-content ml">
                                <p> Rate-Catcher.com an RGR Marketing website is not a lender, broker, or other provider of financial or insurance products or
                                    services. The information you provide to us is not an application for a loan or other financial product
                                    or service. We do not use this information to pre-qualify you for a loan or financial products or
                                    services. We provide the information you give to us to third party providers of these products and
                                    services. If the information you provide to us matches the criteria they are seeking, they will contact
                                    you
                                    directly. In many cases we may deploy SMS to you to facilitate an inbound call to the service or product
                                    provider. We are paid by such providers for each consumer they contact directly and/or provide services
                                    or products. You are not charged for our services. We do not guarantee that a provider or Lender will
                                    accept your registration information or that their products or services will meet your needs. Only the
                                    Lender can provide you with rates, terms and products or services you have inquired about. It may not be
                                    the best product or service available on the market. Not all product or service providers will offer you
                                    the products that we advertise. Completing our forms does not obligate you to purchase a service or
                                    product nor does it obligate a product or service provider to provide you with any particular service
                                    about which you may have inquired. Available rates and terms in our marketing materials are subject to
                                    change without notice. You must be a legal resident of the United States to use this Website and we specifically prohibit access or use by residents of other countries including but not limited to Canadian and European Union Member Citizens. <a href="https://www.nmlsconsumeraccess.org/" target="_blank">Nationwide Mortgage Licensing System (NMLS) ID # 2721</a>.
                                </p> <a href="http://www.refiexpert.net/" target="_blank">Licenses and Disclosures</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
}

export default Description;
