import React from "react";
import logo from "../assets/img/logo.png";
import brandLogo from "../assets/img/brand-logo.png";
import lineImg from "../assets/img/line-img.png";
import formLine from "../assets/img/form--line.png";
import Navlinks from "../Components/Navlinks";
import Description from "../Components/Description";
import ScrollToTop from "../Components/ScrollToTop";
import ReactGA from 'react-ga4';

export default class Step1 extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.runeHawkTalon();

        ReactGA.initialize(process.env.REACT_APP_GA);
        ReactGA.send({ hitType: 'pageview', page: '/step1'});

        ReactGA.event({
            category: 'RC Landing',
            action: 'RC Landing',
            label: 'RC Landing'
        });

        
        
    }
     
    setDefault = (e) =>{

        let option = e.target.querySelector('option').innerText;

        if(option == "Home Type"){         
            if(this.props.values.homeType === "") {
                this.props.manualHandleChange('homeType', 'Single Family');
            }
        }else if(option == "Credit Score") {
            if(this.props.values.creditScore === "") {
                this.props.manualHandleChange('creditScore', 'Good');
            }
        }
    }

    submitStep = () =>{
        let isError = false;
        if (!this.props.values.homeType.length) {
            this.props.setError('homeType');
            isError = true;
        }

        if (!this.props.values.creditScore.length) {
            this.props.setError('creditScore');
            isError = true;
        }

        if (!isError) {
            this.props.next();
        }

        ScrollToTop();
    }

    setDefault = (e) =>{

        let option = e.target.querySelector('option').innerText;

        if(option == "Home Type"){         
            if(this.props.values.homeType === "") {
                this.props.manualHandleChange('homeType', 'Single Family');
            }
        }else if(option == "Credit Score") {
            if(this.props.values.creditScore === "") {
                this.props.manualHandleChange('creditScore', 'Good');
            }
        }
    }

    render () {
        return (
            <>
            <section className="header index-banner">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <a className="navbar-brand brand" href="/">
                            <img src={logo} alt="logo-image" className="img-fluid logo"/>
                        </a>
                    </nav>
                    <span className="logo-text">Catch your lower rate today!</span>
                    <div className="row banner-content align-items-center">
                        <div className="col-lg-7 col-md-7 orderMobileLast">
                            <img src={lineImg} className="img-fluid line-img" alt=""/>
                            <ul className="our-list">
                                <li> Lower your payment</li>
                                <li>Pay off your loan faster</li>
                                <li>Get a low rate for the life of your loan</li>
                            </ul>
                            <div className="brand-logo">
                                <img src={brandLogo} className="img-fluid brand-img transparent-background" alt=""/>
                            </div>
                            {/*<div className="banner-links mm d-flex align-items-center">*/}
                            
                            {/*</div>*/}
                        </div>
                        <div className="col-lg-5 col-md-5">
                            <div className="banner-form">
                                <h1 className="form-heaading mm  text-left">Rates Are Near Historic
                                    <span className="form-color mbb">	Lows</span></h1>
                                <h6 className="form-content2 mm">
                                    See how much you could save in 1 minute</h6>
                                <img src={formLine} className="img-fluid form-line-img" alt=""/>
                                <form>
                                    <input type="hidden" name="universal_leadid" value="" id="leadid_token"/>
                                    <div className="form-group">
                                        <select value={this.props.values.homeType} className={`form-control ${!this.props.isError('homeType') ? '' : 'invalid'}`} onMouseDown={this.setDefault} onChange={this.props.handleChange('homeType')}>
                                            <option value="" disabled>Home Type</option>
                                            <option value="Single Family">Single Family</option>
                                            <option value="Condominium">Condominium</option>
                                            <option value="Multi-Family Dwelling Unit">Multi-Family Dwelling Unit</option>
                                            <option value="Mobile Home">Mobile Home</option>
                                        </select>
                                        {this.props.isError('homeType') &&
                                        <div className="invalid-feedback d-block">
                                            Please select home type.
                                        </div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <select value={this.props.values.creditScore} className={`form-control ${!this.props.isError('creditScore') ? '' : 'invalid'}`} onMouseDown={this.setDefault} onChange={this.props.handleChange('creditScore')}>
                                            <option value="" disabled>Credit Score</option>
                                            <option value="Excellent">Excellent (700+)</option>
                                            <option value="Good">Good (640-699)</option>
                                            <option value="Fair">Fair (560-639)</option>
                                            <option value="Poor">Poor (559 or lower)</option>
                                        </select>
                                        {this.props.isError('creditScore') &&
                                        <div className="invalid-feedback d-block">
                                            Please select credit score.
                                        </div>
                                        }
                                    </div>
                                </form>
                                <div className="form-button">
                                    <a onClick={this.submitStep} className="banner-btn mb text-uppr">continue to step 2
                                        <i className="fas fa-arrow-right btn-arrow"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Description submitStep = {this.submitStep}/>
            </>
        )
    }
}
