import React from "react";

export default function OptOut(props) {
  return (
    <a
      className={
        "pointer-cursor nav-link" + (props.leftSpace ? " space-left" : "")
      }
      href="https://www.folderleafsix.com/o-qkbh-n94-5b4a542ca7830ea75bbb6a1bf7d612f1"
      target="_blank"
    >
      Opt Out
    </a>
  );
}
