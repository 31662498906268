import React, { Component } from 'react';
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfUse from "./TermsOfUse";
import OptOut from "./OptOut";

export default class Navlinks extends Component {
    constructor(props) {
        super(props);
        this.state = { ppIsOpen: false };
    }

    setPpIsOpen = (val) => {
        this.setState({ ppIsOpen: val });
    }

    render() {
        return (
            <>
                <PrivacyPolicy leftSpace={this.props.leftSpace} ppIsOpen={this.state.ppIsOpen} setPpIsOpen={this.setPpIsOpen} />
                <TermsOfUse leftSpace={this.props.leftSpace} setPpIsOpen={this.setPpIsOpen} />
                <OptOut leftSpace={this.props.leftSpace}/>
                <a className={"nav-link" + (this.props.leftSpace ? ' space-left' : '')} target={'_blank'} href="https://privacyportal-eu-cdn.onetrust.com/dsarwebform/942fbf02-bbfe-4a5d-808d-080b06c5651c/3fdf8f1d-7ab3-4a99-bb4e-24965500290d.html">Do Not Sell My Info</a>
            </>
        );
    }
}

Navlinks.defaultProps = {
    leftSpace: false
}
