import React from 'react';
import Navlinks from "./Navlinks";

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row text-center align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="copyright-text d-flex align-items-center mm">
                            © 2021 Rate-Catcher.com Loan Analyst
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-lg-block d-md-block d-sm-block">
                        <div className=" navbar-links mm d-flex justify-content-end">
                            <Navlinks/>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
